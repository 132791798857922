<template>
  <label
    class="checkbox"
    :class="checkboxClass"
  >
    <input
      v-model="statusCheckbox"
      class="checkbox__input"
      type="checkbox"
      :name="name"
    >
    <i class="checkbox__icon"></i>
    <span
      v-if="checkboxText.length > 0"
      :class="[checkboxValueClass ? ('checkbox__value ' + checkboxValueClass) : 'checkbox__value']"
    >{{ checkboxText }} </span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: ['value', 'name', 'checkboxClass', 'checkboxValueClass', 'checkboxText', 'tooltipLength'],
  computed: {
    statusCheckbox: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit('checkboxChange', data);
      },
    },
  },
  // watch: {
  //   this.va
  // },
  methods: {
    showTooltip(text) {
      if (this.tooltipLength) {
        if (text.length > this.tooltipLength) {
          return text;
        }
        return '';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/sass/utils/variables";
  .checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_step {
      .checkbox__icon {
        border: 3px solid $color-moody-blue;
        width: 20px;
        height: 20px;
        top: 2px;
        left: 4px;
      }

      .checkbox__value {
        font-size: 18px;
        line-height: 30px;
        font-family: $font-global, sans-serif;
        color: $color-shark;

        @media (max-width: 600px) {
          font-size: 14px;
        }

      }

      .checkbox__input {
        &:checked + .checkbox__icon {
          &:before {
            content: '\E824';
            font-family: $icon-font;
            font-size: 12px;
            color: $color-moody-blue;
            position: absolute;
            top: -1px;
            right: -2px;
          }
        }
      }
    }

    &_modal {

      .checkbox__input {
        &:checked + .checkbox__icon:before {
          top: 2px;
          right: 0;
        }
      }
      .checkbox__icon {
        border: 3px solid $color-alto;
        width: 20px;
        height: 20px;
        top: 2px;
        left: 4px;

        @media (max-width: 767px) {
          width: 16px;
          height: 16px;
          top: 0;

          &:before {
            font-size: 7px!important;
          }

        }

      }
    }

    &_lg {
      .checkbox__icon {
        width: 37px;
        min-width: 37px;
        height: 37px;
        border-width: 4px;
        border-radius: 8px;

        @media (max-width: 767px) {
          width: 18px;
          height: 18px;
          min-width: 18px;
          min-height: 18px;
          border-width: 2px;
          border-radius: 4px;
        }

        &:before {
          font-size: 20px !important;
          top: 1px;
          right: -4px;

          @media (max-width: 767px) {
            font-size: 9px!important;
            right: 0!important;
            top: 2px!important;
          }

        }

      }

      .checkbox__value {
        /*pointer-events: none;*/
        font-size: 31px;
        font-family: $font-global-semibold;
        margin-left: 30px;

        @media (max-width: 767px) {
          font-size: 16px;
          margin-left: 12px;
          white-space: normal;
        }

      }
    }

    &_white {

      .checkbox__icon {
        border-color: $color-white;
        color: $color-white;
        position: relative;
      }
    }

    &__icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      border: 1px solid $color-alto;
      border-radius: 3px;
      position: relative;
      /*top: 0px!important;*/

    }

    &__input {
      position: absolute;
      transform: scale(0);

      &:checked {
        + .checkbox__icon {

          &:before {
            content: '\E824';
            font-family: $icon-font;
            font-size: 9px;
            color: $color-white;
            position: absolute;
            top: 1px;
            right: -3px;
          }
        }
        + .checkbox__value {
          color: $color-alto;

          + .checkbox__icon_confirm {
            color: $color-alto;
            opacity: 1;
          }
        }
      }
    }

    &__value {
      position: relative;
      top: 1px;
      margin-left: 13px;
      white-space: nowrap;
      user-select: none;
      font: 14px/1 $font-global;
      user-select: none;

      &_bigger {
        font-family: $font-global, sans-serif;
        font-size: 18px;
        color: $color-shark;

        @media (max-width: 767px) {
          font-size: 12px;
          margin-left: 10px;
        }

      }

      &_big {
        font: 18px $font-global-medium;
      }
    }
  }

  .radio-checkbox-row{
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
    margin-bottom: -12px;

    .checkbox{
      margin-right: 16px;
      margin-top: 12px;
      margin-bottom: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    &_small-margin {

      .checkbox {
        margin-right: 20px;
      }
    }

    &_no-wrap {
      flex-wrap: nowrap;
    }
  }
</style>
