<template>
  <div>
    <div class="pricing-step pricing-step_first">
      <div
        v-if="ifLoader"
        class="if-loader"
      ></div>
      <div class="container">
        <button
          class="pricing-step__back"
          @click="backRoute()"
        >
          <i class="icon-arrow-up"></i>
          Back
        </button>
        <h2 class="pricing-step__title">
          Let’s Get Started!
        </h2>
        <form
          class="impact-form"
          autocomplete="off"
        >
          <div class="impact-form-block">
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                First Name
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.firstName"
                  type="text"
                  placeholder="First name"
                  autofocus
                  name="firstName"
                  :class="{'error': validator.errors.has('firstName')}"
                  @keyup="formatField($event)"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('firstName')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('firstName')}"
                  >
                    {{ validator.errors.first('firstName') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Last Name
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.lastName"
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  :class="{'error': validator.errors.has('lastName')}"
                  @keyup="formatField($event)"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('lastName')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('lastName')}"
                  >
                    {{ validator.errors.first('lastName') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Email
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  :class="{'error': validator.errors.has('email')}"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('email')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('email')}"
                  >
                    {{ validator.errors.first('email') }}
                  </span>
                </transition>
              </div>
            </div>
          </div>
          <div class="impact-form-block">
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Your Admin Portal’s URL
              </span>
              <div class="impact-form-item__input-wrap">
                <div class="impact-form-item__input">
                  <div
                    v-if="ifLoaderMini"
                    class="if-loader-mini"
                  ></div>
                  <!--                @keyup="lowerCaseField($event)"-->
                  <!--                @keypress="isLower($event)"-->
                  <!--                v-model="C"-->
                  <input
                    :value="pricingObj.domain"
                    type="text"
                    placeholder="Domain name"
                    class="lowerCase"
                    :class="{'error': validator.errors.has('domain')}"
                    @keyup="formatLowerField($event)"
                    @blur="checkDomain()"
                    @focus="checkDomainFocus()"
                    @input="checkValueDomain()"
                  >
                  <!--                {{domainLower}}-->
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('domain')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('domain')}"
                    >
                      {{ validator.errors.first('domain') }}
                    </span>
                  </transition>
                </div>
                <div class="impact-form-item__url">
                  .impactfactors.net
                </div>
              </div>
            </div>
            <div class="impact-form-block__desc">
              <span>Pick something short and recognizable for your admin portal's address; for instance: use abbreviation letters of your organization's name, so you can easily remember it later.
                For example, for Grace Church, type "gc" this way, your admin portal's address will be gc.impactfactors.net</span>
              <span>Your admin portal’s URL can only contain lowercase letters, numbers and dashes.</span>
              <span>If later you’ll forget your admin URL, simply go to our website <a
                target="_blank"
                href="https://www.impactfactors.net/"
              >www.impactfactors.net</a></span>
              <span>and on top of the right corner click “Client Login”.</span>
              <span>You will need to enter your email address and we will take you to your admin portal.</span>
            </div>
          </div>
          <div class="impact-form-block">
            <div class="impact-form-item">
              <span class="impact-form-item__text">
                <span class="required">*</span>
                Organization Name
              </span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.organization"
                  type="text"
                  placeholder="Organization"
                  name="organization"
                  :class="{'error': validator.errors.has('organization')}"
                  @keyup="formatField($event)"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('organization')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('organization')}"
                  >
                    {{ validator.errors.first('organization') }}
                  </span>
                </transition>
              </div>
            </div>
            <div class="impact-form-item">
              <span class="impact-form-item__text">Website URL</span>
              <div class="impact-form-item__input">
                <input
                  v-model="pricingObj.siteUrl"
                  type="text"
                  placeholder="Website URL"
                  name="siteUrl"
                  :class="{'error': validator.errors.has('siteUrl')}"
                >
                <transition name="fade-msg">
                  <span
                    v-show="validator.errors.has('siteUrl')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('siteUrl')}"
                  >
                    {{ validator.errors.first('siteUrl') }}
                  </span>
                </transition>
              </div>
            </div>
          </div>
          <div class="impact-form-block impact-form-block_size">
            <div class="impact-form-block__box">
              <div class="impact-form-item">
                <span class="impact-form-item__text">Street</span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.address"
                    type="text"
                    placeholder="Address"
                    name="address"
                    @keyup="formatField($event)"
                  >
                </div>
              </div>
              <div class="impact-form-item impact-form-item_address impact-form-item_281">
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.address2"
                    type="text"
                    class="impact-form-item__input"
                    placeholder="Address 2"
                    name="address"
                    @keyup="formatField($event)"
                  >
                </div>
              </div>
            </div>
            <div class="impact-form-block__box">
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  <span class="required">*</span>
                  City
                </span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.city"
                    type="text"
                    class="impact-form-item__input"
                    placeholder="City"
                    name="city"
                    :class="{'error': validator.errors.has('city')}"
                    @keyup="formatField($event)"
                  >
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('city')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('city')}"
                    >
                      {{ validator.errors.first('city') }}
                    </span>
                  </transition>
                </div>
              </div>
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  <span class="required">*</span>
                  Postal Code
                </span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.zip"
                    type="text"
                    placeholder="Zip"
                    name="zip"
                    :class="{'error': validator.errors.has('zip')}"
                  >
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('zip')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('zip')}"
                    >
                      {{ validator.errors.first('zip') }}
                    </span>
                  </transition>
                </div>
              </div>
            </div>
            <div class="impact-form-block__box">
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  <span
                    v-if="countryActive === 'US'"
                    class="required"
                  >
                    *
                  </span>
                  State/Region
                </span>
                <template v-if="countryActive === 'US'">
                  <div class="impact-form-item__input">
                    <SelectWithKeyTemplate
                      :settings="selectStateSettings"
                      @changeSelect="changeState($event)"
                    />
                    <span
                      v-show="validator.errors.has('setState')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('setState')}"
                    >
                      {{ validator.errors.first('setState') }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div class="impact-form-item__input impact-form-item__input_mini">
                    <input
                      v-model="setState"
                      type="text"
                      placeholder="State"
                      name="state"
                      :class="{'error': validator.errors.has('setState')}"
                      @keyup="formatField($event)"
                    >
                    <transition name="fade-msg">
                      <span
                        v-show="validator.errors.has('setState')"
                        class="help"
                        :class="{'is-danger': validator.errors.first('setState')}"
                      >
                        {{ validator.errors.first('setState') }}
                      </span>
                    </transition>
                  </div>
                </template>
                <!--                <div class="impact-form-item__input">-->
                <!--                  <input-->
                <!--                    @keyup="formatField($event)"-->
                <!--                    type="text"-->
                <!--                    placeholder="State"-->
                <!--                    name="state"-->
                <!--                    v-model="pricingObj.state"-->
                <!--                    :class="{'error': validator.errors.has('state')}"-->
                <!--                  >-->
                <!--                  <transition name="fade-msg">-->
                <!--                    <span v-show="validator.errors.has('state')"-->
                <!--                          class="help"-->
                <!--                          :class="{'is-danger': validator.errors.first('state')}"-->
                <!--                    >-->
                <!--                      {{ validator.errors.first('state') }}-->
                <!--                    </span>-->
                <!--                  </transition>-->
                <!--                </div>-->
              </div>
              <div class="impact-form-item impact-form-item_center">
                <div class="impact-form-item impact-form-item_ofset">
                  <span class="impact-form-item__text">Country</span>
                  <div class="impact-form-item__input">
                    <SelectWithKeyTemplate
                      :settings="selectCountrySettings"
                      @changeSelect="changeCountry($event)"
                    />
                    <transition name="fade-msg">
                      <span
                        v-show="validator.errors.has('setCountry')"
                        class="help"
                        :class="{'is-danger': validator.errors.first('setCountry')}"
                      >
                        {{ validator.errors.first('setCountry') }}
                      </span>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!errorTimeZone"
              class="impact-form-block__box"
            >
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  <span class="required">*</span>
                  Time Zone
                </span>
                <div class="impact-form-item__input">
                  <SelectWithKeyTemplate
                    :settings="selectTimeZoneSettings"
                    @changeSelect="changeTimeZone($event)"
                  />
                  <transition name="fade-msg">
                    <span
                      v-show="validator.errors.has('setTimeZone')"
                      class="help"
                      :class="{'is-danger': validator.errors.first('setTimeZone')}"
                    >
                      {{ validator.errors.first('setTimeZone') }}
                    </span>
                  </transition>
                </div>
              </div>
            </div>
          </div>
          <div class="impact-form-block impact-form-block_last">
            <div class="impact-form-block__box">
              <div class="impact-form-item">
                <span class="impact-form-item__text">
                  <span class="required">*</span>
                  Work Phone
                </span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.workPhone"
                    type="text"
                    placeholder="Work Phone"
                    name="workPhone"
                    :class="{'error': validator.errors.has('workPhone')}"
                  >
                  <span
                    v-show="validator.errors.has('workPhone')"
                    class="help"
                    :class="{'is-danger': validator.errors.first('workPhone')}"
                  >
                    {{ validator.errors.first('workPhone') }}
                  </span>
                </div>
              </div>
              <div class="impact-form-item">
                <span class="impact-form-item__text">Ext</span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.workPhoneExt"
                    type="text"
                    placeholder="Work Phone Ext"
                    name="workPhoneExt"
                  >
                </div>
              </div>
            </div>
            <div class="impact-form-block__box">
              <div class="impact-form-item">
                <span class="impact-form-item__text">Mobile Phone</span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.mobilePhone"
                    type="text"
                    placeholder="Mobile Phone"
                    name="mobilePhone"
                  >
                  <!--                    :class="{'error': validator.errors.has('mobilePhone')}"-->
                  <!--                    <span v-show="validator.errors.has('mobilePhone')"-->
                  <!--                          class="help"-->
                  <!--                          :class="{'is-danger': validator.errors.first('mobilePhone')}"-->
                  <!--                    >-->
                  <!--                      {{ validator.errors.first('mobilePhone') }}-->
                  <!--                    </span>-->
                </div>
              </div>
              <div class="impact-form-item">
                <span class="impact-form-item__text">EIN</span>
                <div class="impact-form-item__input">
                  <input
                    v-model="pricingObj.ein"
                    type="text"
                    placeholder="Optional"
                    autofocus
                    name="firstName"
                    @keyup="formatField($event)"
                  >
                  <!--                    :class="{'error': validator.errors.has('ein')}"-->
                  <!--                    <transition name="fade-msg">-->
                  <!--                    <span v-show="validator.errors.has('ein')"-->
                  <!--                          class="help"-->
                  <!--                          :class="{'is-danger': validator.errors.first('ein')}"-->
                  <!--                    >-->
                  <!--                      {{ validator.errors.first('ein') }}-->
                  <!--                    </span>-->
                  <!--                    </transition>-->
                </div>
              </div>
            </div>
            <div class="conditions">
              <checkbox
                class="conditions__check"
                :value="impactTerms"
                :checkboxText="'I agree to the'"
                :checkboxClass="'checkbox_step'"
                @checkboxChange="changeTerms($event)"
              />
              <div class="conditions__text">
                <a
                  href=""
                  class="conditions__link"
                  @click.prevent="showTerms = true"
                >
                  ImpactFactors Terms and Conditions
                </a>
              </div>
            </div>
          </div>
          <input
            ref="submit"
            type="submit"
            class="btn impact-form__btn"
            value="Submit"
            :disabled="!impactTerms"
            :class="{'impact-form__btn_disabled': !impactTerms}"
            @click.prevent="loadSecondStep()"
          >
        </form>
      </div>
    </div>
    <transition
      name="fade-modal"
      appear
    >
      <modals-terms
        v-if="showTerms"
      >
      </modals-terms>
    </transition>
  </div>
</template>

<script>
import pricingApi from '@/api/pricingApi';
import checkbox from '@/components/checkbox';
import SelectWithKeyTemplate from '@/components/selectWithKeyTemplate';
import { Validator, ErrorBag } from 'vee-validate';
import { COUNTRY_LIST } from '@/constants/country';
import { STATE_LIST } from '@/constants/state';
import modalsTerms from '@/components/modals/modalsTerms';

export default {
  name: 'Index',
  components: {
    checkbox,
    modalsTerms,
    SelectWithKeyTemplate,
  },
  data() {
    return {
      domainError: false,
      disabledState: false,
      windowEl: window,
      countryList: COUNTRY_LIST,
      stateList: STATE_LIST,
      ifLoaderMini: false,
      validator: {},
      fucusValue: '',
      impactTerms: false,
      ifLoader: false,
      debug: false,
      errorTimeZone: '',
    };
  },
  computed: {
    showStraightOffModalSignUp: {
      get() {
        return this.$store.getters.showStraightOffModalSignUp;
      },
      set(data) {
        this.$store.commit('setShowStraightOffModalSignUp', data);
      },
    },
    showTerms: {
      get() {
        return this.$store.getters.showTerms;
      },
      set(data) {
        this.$store.commit('changeShowTerms', data);
      },
    },
    // domainLower: {
    //   get: function () {
    //     return this.pricingObj.domain
    //   },
    //   set: function (data) {
    //     console.log(this.pricingObj)
    //     this.pricingObj.domain = data.toLowerCase()
    //   }
    // },
    terms: {
      get() {
        return this.$store.getters.terms;
      },
      set(data) {
        this.$store.commit('setTerms', data);
      },
    },
    selectStateSettings() {
      return {
        optionList: this.stateListing,
        fontFamily: false,
        placeholderText: '',
        selectedKey: this.setState,
        disable: this.disabledState,
        toTop: false,
        tabindex: 1,
        selectClass: 'select-impact select_364',
        dataRecurly: 'state',
      };
    },
    stateListing() {
      const newArr = [];
      for (const key in this.stateList) {
        newArr.push({
          key,
          text: this.stateList[key],
        });
      }
      return newArr;
    },
    stateActive: {
      get() {
        return this.$store.getters.stateActive;
      },
      set(data) {
        this.$store.commit('setStateActive', data);
      },
    },
    setState: {
      get() {
        return this.stateActive;
      },
      set(data) {
        this.stateActive = data;
      },
    },
    stepFirstId: {
      get() {
        return this.$store.getters.stepFirstId;
      },
      set(data) {
        this.$store.commit('setStepFirstId', data);
      },
    },
    countryActive: {
      get() {
        return this.$store.getters.countryActive;
      },
      set(data) {
        this.$store.commit('setCountryActive', data);
      },
    },
    timeZoneActive: {
      get() {
        return this.$store.getters.timeZoneActive;
      },
      set(data) {
        this.$store.commit('setTimeZoneActive', data);
      },
    },
    timeZoneList() {
      const newArr = [];
      console.log(this.$store.getters.timeZoneList);
      for (const i in this.$store.getters.timeZoneList) {
        newArr.push({
          key: i,
          text: this.$store.getters.timeZoneList[i],
        });
      }
      return newArr;
    },
    countryListing() {
      const newArr = [];
      for (let i = 0; i < this.countryList.length; i++) {
        newArr.push({
          key: this.countryList[i].code,
          text: this.countryList[i].name,
        });
      }
      return newArr;
    },
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    selectTimeZoneSettings() {
      return {
        optionList: this.timeZoneList,
        fontFamily: false,
        placeholderText: '',
        selectedKey: this.setTimeZone,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: 'select-impact select_364',
      };
    },
    selectCountrySettings() {
      return {
        optionList: this.countryListing,
        fontFamily: false,
        placeholderText: '',
        selectedKey: this.setCountry,
        disable: false,
        toTop: false,
        tabindex: 1,
        selectClass: 'select-impact select_174',
        dataRecurly: 'country',
      };
    },
    setTimeZone: {
      get() {
        return this.timeZoneActive;
        // return this.$store.getters.timeZoneList
      },
      set(data) {
        this.timeZoneActive = data;
      },
    },
    setCountry: {
      get() {
        return this.countryActive;
      },
      set(data) {
        this.countryActive = data;
        // Current Time Zone
        pricingApi.timezonesList(this.countryActive).then((resp) => {
          console.log(resp);
          this.$store.commit('setTimezoneList', resp.data.tz);
        }).catch((err) => {
          console.log(err);
          this.errorTimeZone = 'US/Central';
        });
      },
    },
  },
  beforeMount() {
    console.log('this.pricingObj', this.pricingObj);
    // if (this.pricingObj.firstName === '' ||
    //   this.pricingObj.lastName === '' ||
    //   this.pricingObj.email === '' ||
    //   this.pricingObj.organization === ''
    // ) {
    //   this.$router.push({name: 'index'})
    // }
    const { organization = '' } = this.pricingObj;
    const organizationArr = organization.split(' ');
    if (organizationArr.length < 2) {
      this.pricingObj.domain = organization.toLowerCase();
    } else {
      const domainName = [];
      organizationArr.forEach((item) => {
        domainName.push(item.charAt(0));
      });
      this.pricingObj.domain = domainName.join('').toLowerCase();
    }
    // el.value.toLowerCase()
    // this.pricingObj.domain
    let period = 2;
    if (
      ((this.pricingObj.services.mobile !== undefined || this.pricingObj.services.tv !== undefined) && (this.pricingObj.period === 'yearly'))
      || ((this.pricingObj.services.mobile !== undefined && this.pricingObj.services.tv !== undefined) && (this.pricingObj.period === 'yearly'))) {
      period = 1;
    }
    console.log(period);
    const {
      city, state, zip, country, timezone,
    } = this.pricingObj;
    console.log('pricingObj', JSON.parse(JSON.stringify(this.pricingObj)));
    if (!city && !state && !zip && !country && !timezone) {
      pricingApi.checkGeoInfo().then((resp) => {
        if (resp) {
          this.countryActive = resp.data.country;
          // this.countryActive = 'US'
          if (resp.data.city !== null) {
            this.pricingObj.city = resp.data.city;
          }
          if (resp.data.state !== null) {
            this.pricingObj.state = resp.data.state;
          }
          if (resp.data.zip !== null) {
            this.pricingObj.zip = resp.data.zip;
          }
          // if (resp.data.timezone !== null && resp.data.country === 'US') {
          //   this.timeZoneActive = resp.data.timezone
          //   console.log('timeZone!!!!!!!!')
          // }
          if (resp.data.timezone !== null) {
            this.timeZoneActive = resp.data.timezone;
          }
          pricingApi.timezonesList(this.countryActive).then((response) => {
            this.$store.commit('setTimezoneList', response.data.tz);
          }).catch((err) => {
            console.log(err);
            this.errorTimeZone = 'US/Central';
          });
        }
      }).catch((err) => {
        console.log(err);
        if (err) {
          this.countryActive = 'US';
          pricingApi.timezonesList(this.countryActive).then((resp) => {
            this.$store.commit('setTimezoneList', resp.data.tz);
          }).catch((err) => {
            console.log(err);
            this.errorTimeZone = 'US/Central';
          });
        }
      });
    }
    pricingApi.getTerms(period, this.pricingObj.organization).then((resp) => {
      console.log(resp);
      this.terms = resp.data.terms;
      console.log(resp);
    }).catch((err) => {
      console.log(err);
    });
    // if (this.countryActive === 'US') {
    //   pricingApi.timezonesList(this.countryActive).then((resp) => {
    //     console.log(resp);
    //     this.$store.commit('setTimezoneList', resp.data.tz);
    //   }).catch((err) => {
    //     console.log(err);
    //   });
    // }
    this.validator = Validator.create();
    // this.$refs.captcha.reset()
    const dict = {
      en: {
        custom: {
          firstName: {
            required: 'The First Name is required.',
          },
          lastName: {
            required: 'The Last Name is required.',
          },
          email: {
            required: 'The Email is required.',
            email: 'The Email field must be a valid email.',
          },
          timeZone: {
            required: 'The Time Zone is required.',
          },
          organization: {
            required: 'The Organization is required.',
          },
          domain: {
            required: 'The Domain is required.',
          },
          siteUrl: {
            required: 'The Site Url is required.',
          },
          city: {
            required: 'The City is required.',
          },
          state: {
            required: 'The State is required.',
          },
          zip: {
            required: 'The Zip is required.',
          },
          // ein: {
          //   required: 'The Ein is required.'
          // },
          // mobilePhone: {
          //   required: 'The Mobile Phone is required.'
          // },
          workPhone: {
            required: 'The Work Phone is required.',
          },
          setCountry: {
            required: 'The Country is required.',
          },
          setTimeZone: {
            required: 'The Time Zone is required.',
          },
          setState: {
            required: 'The State is required.',
          },
        },
      },
    };
    this.validator.localize(dict);
    this.validator.attach({ name: 'firstName', rules: { required: true } });
    this.validator.attach({ name: 'lastName', rules: { required: true } });
    this.validator.attach({ name: 'email', rules: { required: true, email: true } });
    this.validator.attach({ name: 'organization', rules: { required: true } });
    this.validator.attach({ name: 'domain', rules: { required: true } });
    this.validator.attach({ name: 'setState', rules: { required: true } });
    this.validator.attach({ name: 'siteUrl', rules: { required: true } });
    this.validator.attach({ name: 'city', rules: { required: true } });
    this.validator.attach({ name: 'state', rules: { required: true } });
    this.validator.attach({ name: 'zip', rules: { required: true } });
    this.validator.attach({ name: 'timeZone', rules: { required: true } });
    // this.validator.attach({name: 'ein', rules: {'required': true}})
    // this.validator.attach({name: 'mobilePhone', rules: {'required': true}})
    this.validator.attach({ name: 'workPhone', rules: { required: true } });
    this.validator.attach({ name: 'setCountry', rules: { required: true } });
    this.validator.attach({ name: 'setTimeZone', rules: { required: true } });

    const to = this.$route;
    console.log(this.$store.getters.computedPricingObj);
    const nearestWithMeta = to.meta.metaTags;
    nearestWithMeta.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  },
  beforeDestroy() {
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
    // window.removeEventListener('click', this.loadSecondStep)
  },
  methods: {
    backRoute() {
      console.warn(this.$router);
      this.$router.go(-1);
    },
    isLower() {
      this.pricingObj.domain.toLowerCase();
      // console.log(e)
      console.log(this.pricingObj.domain);
    },
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollY;
      const perTick = difference / duration * 10;
      const thisEl = this;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollY + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollY === to) return;
        thisEl.scrollTo(element, to, duration - 10);
      }, 10);
    },
    // lowerCaseField: function (ev) {
    //   let el = ev.target
    //   let val = el.value.replace(/\s{2,}/g, ' ').split(' ')
    //   let res = []
    //   for (let idx in val) {
    //     if (val[idx] !== ' ') {
    //       // res.push(val[idx].substr(0, 1).toLowerCase() + val[idx].substr(1).toLowerCase())
    //       console.log(val[idx].substr(0).toLowerCase())
    //       res.push(val[idx].substr(0).toLowerCase() + val[idx].substr(0).toLowerCase())
    //     }
    //   }
    //   el.value = res.join(' ')
    //   console.log(el.value)
    //   console.log(this.pricingObj)
    // },
    formatField(ev) {
      const el = ev.target;
      const val = el.value.replace(/\s{2,}/g, ' ').split(' ');
      const res = [];
      for (const idx in val) {
        if (val[idx] !== ' ') {
          res.push(val[idx].substr(0, 1).toUpperCase() + val[idx].substr(1).toLowerCase());
        }
      }
      el.value = res.join(' ');
    },
    formatLowerField(ev) {
      const el = ev.target;
      el.value = el.value.toLowerCase();
      this.pricingObj.domain = el.value;
    },
    checkDomainFocus() {
      this.fucusValue = this.pricingObj.domain;
      // this.fucusValue = this.domainLower
    },
    checkDomain() {
      if (this.fucusValue !== this.pricingObj.domain) {
      // if (this.fucusValue !== this.domainLower) {
        this.ifLoaderMini = true;
        pricingApi.checkDomain(this.pricingObj.domain).then((resp) => {
        // pricingApi.checkDomain(this.domainLower).then((resp) => {
          this.ifLoaderMini = false;
          console.log(resp);
          console.log(resp.data.error);
          this.domainError = resp.data.error;
          if (resp.data.exists) {
            const bagError = new ErrorBag();
            bagError.add({
              field: 'domain',
              msg: resp.data.error,
            });
            console.log(bagError);
            this.validator.errors = bagError;
          }
        }).catch((err) => {
          this.ifLoaderMini = false;
          console.log(err);
        });
      }
    },
    checkValueDomain() {
      // console.log(this.validator.errors)
      if (this.validator.errors.items.length !== 0 && this.validator.errors.items[0].field === 'domain') {
        const bagError = new ErrorBag();
        bagError.clear({
        });
        console.log(bagError);
        this.validator.errors = bagError;
      }
    },
    changeTerms(e) {
      this.impactTerms = e;
    },
    changeState(key) {
      this.setState = key;
    },
    loadSecondStep() {
      console.log('this.errorTimeZone', this.errorTimeZone);
      console.log('this.setTimeZone', this.setTimeZone);
      const timezone = this.setTimeZone || this.errorTimeZone;
      console.log('timezone', timezone);
      this.validator.validateAll({
        firstName: this.pricingObj.firstName,
        lastName: this.pricingObj.lastName,
        email: this.pricingObj.email,
        organization: this.pricingObj.organization,
        domain: this.pricingObj.domain,
        // 'domain': this.domainLower,
        // 'siteUrl': this.pricingObj.siteUrl,
        city: this.pricingObj.city,
        setState: this.countryActive === 'US' ? this.setState : true,
        zip: this.pricingObj.zip,
        // 'mobilePhone': this.pricingObj.mobilePhone,
        workPhone: this.pricingObj.workPhone,
        setTimeZone: timezone,
        setCountry: this.setCountry,
      }).then((result) => {
        if (result) {
          window.fbq('trackCustom', 'Sign Up Form Step 4 - Client Info');
          let type = 0;
          if (this.pricingObj.type === 'church') {
            type = 1;
          } else {
            type = 2;
          }
          const { period, amount } = this.pricingObj;
          const amountCost = period === '2yearly' ? amount * 24 * 0.8 : amount * 12 * 0.9;
          const totalAmount = period === 'monthly' ? amount : amountCost;
          const allData = {
            organization: this.pricingObj.organization,
            email: this.pricingObj.email,
            firstName: this.pricingObj.firstName,
            lastName: this.pricingObj.lastName,
            type,
            attendance: this.pricingObj.attendance,
            amount: this.debug ? 0.01 : totalAmount,
            period: this.pricingObj.period,
            services: JSON.stringify(this.pricingObj.services),
            promo: this.pricingObj.promo,
            setup: this.debug ? 0 : this.pricingObj.setup,
            address: this.pricingObj.address,
            address2: this.pricingObj.address2,
            city: this.pricingObj.city,
            state: this.setState,
            zip: this.pricingObj.zip,
            // mobilePhone: this.pricingObj.mobilePhone,
            workPhone: this.pricingObj.workPhone,
            workPhoneExt: this.pricingObj.workPhoneExt,
            siteUrl: this.pricingObj.siteUrl,
            // ein: this.pricingObj.ein,
            timezone,
            country: this.setCountry,
            domain: this.pricingObj.domain,
            // domain: this.domainLower
          };
          this.ifLoader = true;
          pricingApi.saveProfile(allData).then((resp) => {
            this.ifLoader = false;
            console.log('saveProfile', resp);
            const { error, needPayment } = resp.data;
            if (error) {
              const bagError = new ErrorBag();
              for (let i = 0; i < error.length; i++) {
                bagError.add({
                  field: error[i].name,
                  msg: error[i].error,
                });
              }
              console.log(bagError);
              this.validator.errors = bagError;
              this.$nextTick(() => {
                const myElement = document.getElementsByClassName('is-danger');
                if (myElement[0] !== undefined) {
                  const rec = myElement[0].getBoundingClientRect();
                  const res = rec.top + window.scrollY;
                  this.scrollTo(window, res - 60, 300, 'clear');
                }
              });
            } else if (!needPayment) {
              this.ifLoader = false;
              window.fbq('trackCustom', 'Sign Up Form Step 6 - Admin Ready');
              this.$router.push({ name: 'registrationconfirmation' });
            } else {
              this.stepFirstId = resp.data.id;
              this.ifLoader = false;
              this.$router.push({ name: 'pricingStep2' });
            }
          }).catch((err) => {
            this.ifLoader = false;
            console.log(err);
          });
        } else {
          this.$nextTick(() => {
            const myElement = document.getElementsByClassName('is-danger');
            if (myElement[0] !== undefined) {
              const rec = myElement[0].getBoundingClientRect();
              const res = rec.top + window.scrollY;
              this.scrollTo(window, res - 60, 300, 'clear');
            }
          });
        }
      });
    },
    changeTimeZone(key) {
      this.setTimeZone = key;
    },
    changeCountry(key) {
      this.setCountry = key;
      // if (key === 'US') {
      //   this.disabledState = false
      // } else {
      //   this.disabledState = true
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../src/assets/sass/utils/variables';

  .container {
    max-width: 985px;
    width: 100%;
  }

  .pricing-step {
    margin-top: 24px;
    margin-bottom: 80px;

    @media (max-width: 767px) {
      padding-top: 60px;
    }

    &__back {
      display: inline-flex;
      align-items: center;
      padding: 9px 11px 7px 6px;
      border: none;
      cursor: pointer;
      color: #b5b6b6;
      background-color: $color-porcelain;
      font-size: 16px;
      font-weight: 300;
      border-radius: 4px;
      transition: background .15s ease-out, color .15s linear;
      font-family: $font-global, sans-serif;
      margin-bottom: 15px;

      i {
        font-size: 14px;
        margin-right: 5px;
        transform: rotate(-90deg);
      }

      &:hover {
        background: transparent;
        color: $color-moody-blue;
      }
    }

    &__title {
      font-size: 45px;
      line-height: 56px;
      margin-bottom: 7px;

      @media (max-width: 767px) {
        font-size: 36px;
        line-height: 40px;
      }

    }
  }

  .impact-form {
    user-select: none;
    max-width: 959px;
    width: 100%;
    margin: auto;

    @media (max-width: 767px) {
      max-width: 550px;
    }

    &__btn {
      margin: auto;
      margin-top: 5px;
      background: $color-moody-blue;
      height: 48px;
      border: none;
      font-family: $font-global-medium;
      font-size: 21px;
      color: $color-white;
      padding: 0 6px;
      width: 100%;
      max-width: 305px;
      border-radius: 5px;
      text-decoration: none;
      transition: opacity .2s ease-in, background .2s ease-in;
      user-select: none;
      -webkit-appearance: none;

      &_disabled {
        background: $color-gray;
        pointer-events: none;
      }

      &:hover {
        opacity: .8;
      }

      &:active {
        background: $color-martinique;
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, .5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }

      @keyframes ripple {
        0% {
          transform: scale(0, 0);
          opacity: 1;
        }
        20% {
          transform: scale(25, 25);
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: scale(40, 40);
        }
      }

      &:focus:not(:active)::after {
        animation: ripple 1.5s ease-out;
      }
    }

    &-block {
      border-bottom: 1px solid $color-alto;
      padding: 40px 15px;
      padding-bottom: 23px;
      padding-left: 58px;
      position: relative;

      @media (max-width: 767px) {
        padding-left: 15px;
      }

      @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 7px;
      }

      &_size {
        padding-right: 0;
      }

      &__box {
        display: flex;
        align-items: center;

        @media (max-width: 1000px) {
          flex-wrap: wrap;
        }

        .impact-form-item + .impact-form-item {
          margin-left: 16px;

          @media (max-width: 1000px) {
            margin-left: 0;
          }

          .impact-form-item__text {
            width: 110px;

            @media (max-width: 1000px) {
              width: 200px!important;
            }

            @media (max-width: 870px) {
              width: 135px!important;
            }

          }

          .impact-form-item__input {
            input {
              width: 174px;

              @media (max-width: 1000px) {
                width: 364px!important;
              }

              @media (max-width: 767px) {
                width: 100% !important;
              }

            }
          }
        }
      }

      &_last {
        border-bottom: none;

        .impact-form-item + .impact-form-item {
          .impact-form-item__text {
            width: 24px;
          }

          .impact-form-item__input {
            input {
              width: 259px;
            }
          }
        }
      }

      &__desc {
        font-size: 14px;
        line-height: 18px;
        font-family: $font-global, sans-serif;
        color: $color-jumbo;
        text-align: left;
        margin-bottom: 20px;
        margin-left: 216px;

        @media (max-width: 870px) {
          margin-left: 151px;
        }

        @media (max-width: 767px) {
          margin-left: 0;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 0;
          margin-top: 10px;
        }

        a {
          color: $color-ship-cove;
          font-family: "AFuturaRound", sans-serif;
          text-decoration: none;
          letter-spacing: 2.3px;
          text-transform: lowercase;
          margin-left: 5px;
          top: 0;
          position: relative;
          font-size: inherit;
          line-height: 18px;
          transition: opacity .15s ease-in;

          &:hover {
            opacity: .6;
          }
        }

        span {
          display: block;
        }
      }
    }

    &-item {
      position: relative;
      height: 40px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        display: block;
        height: auto;
        width: 100%;
        margin-bottom: 5px;
      }

      &_ofset {
        margin-top: 16px;
      }

      &_281 {

        @media (max-width: 1000px) {
          margin-left: 216px!important;
        }

        @media (max-width: 870px) {
          margin-left: 151px!important;
        }

        @media (max-width: 767px) {
          margin-left: 0!important;
        }

        input {
          width: 300px !important;

          @media (max-width: 1000px) {
            width: 364px!important;
          }

          @media (max-width: 767px) {
            width: 100% !important;
          }

        }
      }

      &__url {
        font-size: 18px;
        line-height: 30px;
        font-family: $font-global, sans-serif;
        color: $color-chateau-green;
        margin-left: 8px;

        @media (max-width: 767px) {
          font-size: 14px;
        }

      }

      &__text {
        font-size: 18px;
        line-height: 30px;
        font-family: $font-global, sans-serif;
        margin-right: 16px;
        position: relative;
        top: -1px;
        width: 200px;
        text-align: right;

        @media (max-width: 870px) {
          width: 135px;
          min-width: 135px;
        }

        @media (max-width: 767px) {
          width: 100%;
          font-size: 14px;
        }

      }

      &__input {
        position: relative;

        &-wrap {
          display: flex;
          align-items: center;

          @media (max-width: 767px) {
            .impact-form-item__input {
              flex-grow: 1;
            }
          }

        }

        input {
          width: 364px;
          border: 1px solid $color-alto;
          border-radius: 4px;
          padding-left: 10px;
          padding-right: 10px;
          font-family: $font-global;
          font-size: 18px;
          color: $color-shark;
          height: 40px;
          transition: border-color .15s ease-in;

          @media (max-width: 767px) {
            width: 100%;
            font-size: 14px;
          }

          &:hover {
            border-color: $color-french-gray;
          }

          &:focus {
            border-color: $color-royal-blue-third;
          }
          &:focus::placeholder {
            opacity: 0;
          }

          &::-webkit-input-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &::-moz-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &:-moz-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &:-ms-input-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &::placeholder {
            color: $color-alto;
            font-family: $font-global;
          }
        }
      }
    }
  }

  .conditions {
    text-align: center;
    margin-top: 17px;
    display: flex;
    justify-content: center;
    margin-right: 45px;
    margin-bottom: 2px;

    @media (max-width: 600px) {
      display: block;
      text-align: left;
      margin-right: 0;

      &__check {
        display: inline-flex!important;
      }

    }

    &__text {
      font-size: 18px;
      line-height: 30px;
      font-family: $font-global, sans-serif;
      color: $color-shark;

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 18px;
        display: inline;
      }

    }

    &__link {
      color: $color-ship-cove;
      font-family: $font-futura, sans-serif;
      text-decoration: none;
      letter-spacing: 2.3px;
      text-transform: uppercase;
      margin-left: 10px;
      top: 1px;
      position: relative;
      font-size: 14px;
      line-height: 18px;
      transition: opacity .15s ease-in;

      @media (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
        margin-left: 2px;
        top: -4px;
      }

      &:hover {
        opacity: .6;
      }
    }
  }

</style>

<style lang="scss" scoped>
  @import '../src/assets/sass/utils/variables';
  .lowerCase {
    text-transform: lowercase;
  }

  .required {
    color: $color-punch;
  }

  .error {
    border-color: $color-punch !important;
    background-color: $color-white !important;
  }
  .help {
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 12px;
    color: $color-punch;
    box-shadow: none;
    padding: 0;
    background: transparent;
    transform: translateY(0);

    @media (max-width: 767px) {
      top: 100%
    }

  }

  .if-loader-mini {
    /*position: fixed;*/
    position: absolute;
    top: 0;
    /*left: 0;*/
    right: 0;
    height: 40px;
    width: 40px;
    /*height: 100%;*/
    /*width: 100%;*/
    /*background: rgba(0, 0, 0, .5);*/
    z-index: 100;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 65;
      width: 100%;
      height: calc(100% + 1px);
      top: 0;
      left: 0;
      margin-top: -1px;
      //background-color: rgba($color-black, 0.2);
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 66;
      left: 50%;
      /*top: 50%;*/
      top: 45%;
      transform: translate(-50%, -100px);
      color: $color-moody-blue;
      /*font-size: 10px;*/
      font-size: 4px;
      margin: 100px auto;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      text-indent: -9999em;
      animation: loader 1.3s infinite linear;
    }
  }

  @keyframes loader {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
</style>
