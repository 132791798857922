<template>
  <transition
    name="fade-block-wait"
    appear
    mode="out-in"
  >
    <div
      v-shortkey="['esc']"
      class="modals-terms"
      @contextmenu.self.prevent
      @click="showTerms = false"
      @shortkey="showTerms = false"
    >
      <div
        class="modals-terms__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          class="modals-terms__close"
          @click="showTerms = false"
        >
          <i class="icon-close-modal"></i>
        </div>
        <VuePerfectScrollbar class="modals-terms__scroll">
          <div
            class="modals-terms__desc"
            v-html="terms"
          >
          </div>
        </VuePerfectScrollbar>
      </div>
    </div>
  </transition>
</template>

<script>
// import pricingApi from '@/api/pricingApi'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'ModalsTerms',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
    };
  },
  computed: {
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    showTerms: {
      get() {
        return this.$store.getters.showTerms;
      },
      set(data) {
        this.$store.commit('changeShowTerms', data);
      },
    },
    terms: {
      get() {
        return this.$store.getters.terms;
      },
      set(data) {
        this.$store.commit('setTerms', data);
      },
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../../assets/sass/utils/variables';

  .modals-terms {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    /*transition: opacity .5s ease-in;*/
    background: rgba(0, 0, 0, .8);
    padding: 15px;
    /*&__bg {*/
      /*background: rgba(0, 0, 0, 0);*/
      /*animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;*/
    /*}*/

    &__content {
      max-width: 1000px;
      width: 100%;
      background: $color-white;
      border-radius: 10px;
      position: relative;
      padding: 60px 30px;
    }

    &__scroll {
      max-height: 70vh;
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__desc {
      h1 {
        font-family: $font-global-bold, sans-serif;
        line-height: 48px;
        font-size: 37px;
        text-align: left;
        margin-bottom: 12px;
        color: $color-jumbo;

        @media (max-width: 1139px) {
          font-size: 25px;
        }

        @media (max-width: 767px) {
          font-size: 20px;
          margin-bottom: 10px;
        }

      }

      p {
        font-family: $font-global, sans-serif;
        color: $color-black;
        line-height: 24px;
        font-size: 14px;
        padding-right: 10px;
        margin-bottom: 10px;

        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 1.5;
        }

      }

      b {
        font-weight: bold;
      }
    }
  }
</style>
